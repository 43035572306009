import { NgStyle } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-floating-info-box',
  templateUrl: './floating-info-box.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgStyle],
})
export class FloatingInfoBoxComponent {
  @Input() customElementId!: string;
  @Input() hasPadding = true;
  @Input() hasPointerEventsNone = false;
  @Input() info!: string | undefined;
  @Input() isHTML = false;
  @Input() isRelative = false;
  @Input() isVisible = true;
  @Input() leftOffset!: number;
  @Input() maxWidth!: string;
  @Input() topOffset!: number;
  @Input() withCustomBodyComponent = false;
  @Input() withFadeIn = false;

  readonly baseElementId = 'floating-info-box';
  readonly defaultTopOffset = '0';
  readonly defaultLeftOffset = '0';
}
