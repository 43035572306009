import { Injectable } from '@angular/core';
import {
  BehaviorSubject,
  distinctUntilChanged,
  map,
  type Observable,
} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CustomTitleService {
  private readonly customTitlesShown = new BehaviorSubject(new Set<string>());
  private readonly customTitlesShown$: Observable<Set<string>> =
    this.customTitlesShown
      .asObservable()
      .pipe(
        distinctUntilChanged(
          (previous, current) => previous.size !== current.size,
        ),
      );

  readonly isAnyCustomTitleShown$: Observable<boolean> =
    this.customTitlesShown$.pipe(
      map((customTitlesShown) => customTitlesShown.size > 0),
      distinctUntilChanged(),
    );

  addCustomTitleToShown(id: string): void {
    this.customTitlesShown.next(this.customTitlesShown.getValue().add(id));
  }

  removeCustomTitleFromShown(id: string): void {
    const customTitlesShown = this.customTitlesShown.getValue();
    customTitlesShown.delete(id);

    this.customTitlesShown.next(customTitlesShown);
  }
}
