<div
  class="tw-z-[9999] tw-mr-10 tw-w-max tw-rounded-lg tw-bg-white tw-shadow-[0_2px_8px_rgb(0_0_0_/_15%)] print:tw-hidden"
  [attr.id]="customElementId || baseElementId"
  [ngStyle]="{ opacity: isVisible ? 1 : 0 }"
  [style.left.px]="leftOffset || defaultLeftOffset"
  [style.maxWidth]="maxWidth || 'auto'"
  [style.padding.px]="hasPadding ? 8 : 0"
  [style.pointerEvents]="hasPointerEventsNone ? 'none' : 'auto'"
  [style.position]="isRelative ? 'relative' : 'absolute'"
  [style.top.px]="topOffset || defaultTopOffset"
>
  @if (withCustomBodyComponent) {
    <ng-content />
  } @else if (isHTML) {
    <div [innerHTML]="info"></div>
  } @else {
    <p
      class="tw-overflow-anywhere tw-mb-0 tw-text-12 tw-leading-4 tw-text-black"
    >
      {{ info }}
    </p>
  }
</div>
