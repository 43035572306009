import { AnimationBuilder } from '@angular/animations';
import { DOCUMENT } from '@angular/common';
import {
  Directive,
  HostListener,
  inject,
  Input,
  input,
  Renderer2,
  ViewContainerRef,
  type ComponentRef,
  type EmbeddedViewRef,
  type OnDestroy,
  type TemplateRef,
} from '@angular/core';
import { Subject, takeUntil, timer } from 'rxjs';
import { fadeInAnimation } from '../../animations/fade-in.animation';
import { fadeOutAnimation } from '../../animations/fade-out.animation';
import { FloatingInfoBoxComponent } from '../../components/floating-info-box/floating-info-box.component';
import { WINDOW } from '../../consts/core.const';
import { generateRandomId } from '../../helpers/generate-random-id/generate-random-id.helper';
import { isNotNullOrUndefined } from '../../helpers/is-not-null-or-undefined/is-not-null-or-undefined.helpers';
import { CustomTitleService } from '../../services/custom-title.service';
import { TouchDeviceDetectorService } from '../../services/touch-device-detector.service';

const defaultMaxWidth = '250px';
const defaultShowDelay = 500;
const defaultHideDelay = 0;
const testId = 'customTitleTooltip';

/**
 * @deprecated
 */
@Directive({
  selector: '[appCustomTitle]',
})
export class CustomTitleDirective implements OnDestroy {
  readonly #document = inject(DOCUMENT);
  readonly #window = inject(WINDOW);
  readonly #animationBuilder = inject(AnimationBuilder);
  readonly #customTitleService = inject(CustomTitleService);
  readonly #renderer = inject(Renderer2);
  readonly #vcReference = inject(ViewContainerRef);

  // TODO: split into separate components
  @Input()
  appCustomTitle? = '';
  @Input()
  appCustomTitleBlockShowing? = false;
  @Input()
  appCustomTitleHasPadding? = true;
  @Input()
  appCustomTitleHasPointerEventsNone? = true;
  @Input()
  appCustomTitleHideDelay? = defaultHideDelay;
  @Input()
  appCustomTitleHidesOnClick? = false;
  @Input()
  appCustomTitleIsHTML? = false;
  @Input()
  appCustomTitleMaxWidth? = defaultMaxWidth;
  @Input()
  appCustomTitleShowDelay? = defaultShowDelay;
  @Input()
  appCustomTitleTrackShowing? = false;
  @Input()
  appCustomTitleShouldShowAgainOnMouseClick? = false;

  /**
   * @deprecated
   * Used for old supplier list headers, do not use
   */
  @Input()
  offsetRight = 0;

  @Input()
  appCustomTitleTemplate?: TemplateRef<unknown>;
  @Input()
  appCustomTitleTemplateContext: unknown;
  @Input()
  appCustomTitleOpenOnClick? = false;

  /**
   * NOTE:
   * Enabling this mode makes some changes to how tooltips are handled on touch devices.
   * Touch devices do not support hovering effect. This mode uses long press detection
   * approach instead.
   */
  readonly appCustomTitleUseExperimentalTouchDevicesSupport =
    input<boolean>(false);
  readonly isTouchDevice = inject(TouchDeviceDetectorService).isTouchDevice;
  readonly #LONG_TOUCH_DURATION_MILLIS: number = 1000;
  #longTouchDetectionTimeout: ReturnType<typeof setTimeout> | undefined;

  readonly #ADDITIONAL_X_OFFSET = 7;
  readonly #ADDITIONAL_Y_OFFSET = 7;
  readonly #unsubscribe = new Subject<void>();

  #customTitleComponent: ComponentRef<FloatingInfoBoxComponent> | null =
    // eslint-disable-next-line unicorn/no-null -- TODO: delete the comment and fix the error
    null;
  #isMouseOnElement = false;
  #xMouseCoordinate!: number;
  #yMouseCoordinate!: number;

  #domElem!: HTMLElement;
  #gate!: HTMLElement;

  #embeddedView: EmbeddedViewRef<unknown> | undefined;

  readonly #id = generateRandomId();

  #unlistenWindowClick?: () => void;

  ngOnDestroy(): void {
    this.#unsubscribe.next();
    this.#unsubscribe.complete();
    this.#destroy();
  }

  // FIXME: Combine mouse events using RxJS

  @HostListener('click')
  onClick(): void {
    // eslint-disable-next-line unicorn/consistent-function-scoping -- TODO: delete the comment and fix the error
    const callback = () => {
      if (this.#isMouseOnElement) {
        this.#displayWithDelay();
      }
    };
    this.#destroy(
      this.appCustomTitleShouldShowAgainOnMouseClick ? callback : undefined,
    );
  }

  @HostListener('click', ['$event'])
  onMouseClick(mouseEvent: MouseEvent): void {
    if (!this.appCustomTitleOpenOnClick) {
      return;
    }
    if (this.#isMouseOnElement && !this.#customTitleComponent) {
      this.#xMouseCoordinate = mouseEvent.clientX;
      this.#yMouseCoordinate = mouseEvent.clientY;
      if (!this.appCustomTitleBlockShowing) {
        this.#displayWithDelay();
      }
    }
  }

  @HostListener('contextmenu', ['$event'])
  onContextMenu(event: Event): void {
    if (this.#isTouchModeActive()) {
      event.preventDefault();
    }
  }

  @HostListener('touchstart', ['$event'])
  onTouchStart(touchEvent: TouchEvent): void {
    if (this.#isTouchModeActive()) {
      this.#displayWhenLongTouchIsDetected(touchEvent);
    }
  }

  @HostListener('touchend')
  onTouchEnd(): void {
    if (this.#isTouchModeActive()) {
      this.#destroy();
    }
  }

  @HostListener('mouseenter')
  onMouseEnter(): void {
    this.#isMouseOnElement = true;
  }

  @HostListener('mousemove', ['$event'])
  onMouseMove(mouseEvent: MouseEvent): void {
    if (this.appCustomTitleOpenOnClick || this.#isTouchModeActive()) {
      return;
    }
    if (this.#isMouseOnElement && !this.#customTitleComponent) {
      this.#xMouseCoordinate = mouseEvent.clientX;
      this.#yMouseCoordinate = mouseEvent.clientY;
      if (!this.appCustomTitleBlockShowing) {
        this.#displayWithDelay();
      }
    }
  }

  @HostListener('focusout')
  onFocusOut(): void {
    this.#handleMouseOutsideElement();
  }

  @HostListener('mouseleave')
  onMouseLeave(): void {
    this.#handleMouseOutsideElement();
  }

  #handleMouseOutsideElement(): void {
    this.#isMouseOnElement = false;
    if (!this.appCustomTitleHidesOnClick && !this.#isTouchModeActive()) {
      this.#destroy();
    }
  }

  #displayWithDelay(): void {
    timer(this.appCustomTitleShowDelay ?? defaultShowDelay)
      .pipe(takeUntil(this.#unsubscribe))
      .subscribe(() => {
        if (this.#isMouseOnElement && !this.#customTitleComponent) {
          this.#showCustomTitle();
        }
      });
  }

  #showCustomTitle(): void {
    if (this.appCustomTitle || this.appCustomTitleTemplate) {
      if (this.appCustomTitleHidesOnClick && !this.#unlistenWindowClick) {
        this.#unlistenWindowClick = this.#renderer.listen(
          'window',
          'click',
          () => {
            if (!this.#isMouseOnElement) {
              this.#destroy();
            }
          },
        );
      }
      if (this.appCustomTitleTrackShowing) {
        this.#customTitleService.addCustomTitleToShown(this.#id);
      }

      this.#embeddedView =
        this.appCustomTitleTemplate ?
          this.#vcReference.createEmbeddedView(
            this.appCustomTitleTemplate,
            this.appCustomTitleTemplateContext,
          )
        : undefined;

      this.#customTitleComponent = this.#vcReference.createComponent(
        FloatingInfoBoxComponent,
        {
          projectableNodes: [
            this.#embeddedView ? this.#embeddedView.rootNodes : [],
          ],
        },
      );

      this.#customTitleComponent.instance.info = this.appCustomTitle;
      this.#customTitleComponent.instance.leftOffset =
        this.#xMouseCoordinate + this.#ADDITIONAL_X_OFFSET - this.offsetRight;
      this.#customTitleComponent.instance.topOffset =
        this.#yMouseCoordinate + this.#ADDITIONAL_Y_OFFSET;
      this.#customTitleComponent.instance.hasPointerEventsNone = Boolean(
        this.appCustomTitleHasPointerEventsNone,
      );
      this.#customTitleComponent.instance.maxWidth =
        this.appCustomTitleMaxWidth ?? defaultMaxWidth;
      this.#customTitleComponent.instance.withFadeIn = true;
      this.#customTitleComponent.instance.isVisible = false;
      this.#customTitleComponent.instance.isHTML = Boolean(
        this.appCustomTitleIsHTML,
      );
      this.#customTitleComponent.instance.customElementId = generateRandomId();
      this.#customTitleComponent.instance.withCustomBodyComponent =
        !!this.appCustomTitleTemplate;
      this.#customTitleComponent.instance.hasPadding = Boolean(
        this.appCustomTitleHasPadding,
      );
      this.#domElem = (
        this.#customTitleComponent
          .hostView as EmbeddedViewRef<FloatingInfoBoxComponent>
      ).rootNodes[0] as HTMLElement;
      this.#domElem.dataset['testid'] = testId;

      this.#createGate();
      this.#renderer.appendChild(this.#document.body, this.#gate);

      this.#customTitleComponent.changeDetectorRef.markForCheck();
      this.#handlePotentialScreenOverflowInterception();
    }
  }

  #createGate(): void {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- TODO: delete the comment and fix the error
    this.#gate = this.#renderer.createElement('div');
    this.#renderer.setStyle(this.#gate, 'position', 'fixed');
    this.#renderer.setStyle(this.#gate, 'z-index', '9999');
    this.#renderer.setStyle(this.#gate, 'top', '0');
    this.#renderer.setStyle(this.#gate, 'left', '0');
    this.#renderer.setStyle(this.#gate, 'width', '100%');
    this.#renderer.setStyle(this.#gate, 'height', '100%');
    this.#renderer.setStyle(this.#gate, 'pointer-events', 'none');

    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- TODO: delete the comment and fix the error
    const innerGate = this.#renderer.createElement('div');
    this.#renderer.setStyle(innerGate, 'position', 'relative');
    this.#renderer.setStyle(innerGate, 'overflow', 'hidden');
    this.#renderer.setStyle(innerGate, 'width', '100%');
    this.#renderer.setStyle(innerGate, 'height', '100%');

    this.#renderer.appendChild(innerGate, this.#domElem);
    this.#renderer.appendChild(this.#gate, innerGate);
  }

  #handlePotentialScreenOverflowInterception(): void {
    // NOTE: query selector doesn't accept selectors starting with number
    setTimeout(() => {
      // eslint-disable-next-line unicorn/prefer-query-selector -- TODO: delete the comment and fix the error
      const renderedCustomTitleElement = this.#document.getElementById(
        String(this.#customTitleComponent?.instance.customElementId),
      );
      const customTitleComponentRect =
        renderedCustomTitleElement?.getBoundingClientRect();

      if (customTitleComponentRect && this.#customTitleComponent) {
        const xOverflow =
          customTitleComponentRect.right - this.#window.innerWidth;
        const yOverflow =
          customTitleComponentRect.bottom - this.#window.innerHeight;

        if (yOverflow > 0) {
          this.#renderer.setStyle(
            renderedCustomTitleElement,
            'top',
            `${
              this.#customTitleComponent.instance.topOffset -
              yOverflow -
              this.#ADDITIONAL_Y_OFFSET
            }px`,
          );
        }

        if (xOverflow > 0) {
          this.#renderer.setStyle(
            renderedCustomTitleElement,
            'left',
            `${
              this.#customTitleComponent.instance.leftOffset -
              xOverflow -
              this.#ADDITIONAL_X_OFFSET
            }px`,
          );
        }
      }

      this.#showAnimation();
    }, 10);
  }

  #showAnimation(): void {
    if (this.#customTitleComponent) {
      const animationFactory = this.#animationBuilder.build(fadeInAnimation);

      const fadeInPlayer = animationFactory.create(this.#domElem.firstChild);

      fadeInPlayer.play();
    }
  }

  #destroy(callback?: () => void): void {
    if (this.#unlistenWindowClick) {
      this.#unlistenWindowClick();
      this.#unlistenWindowClick = undefined;
    }

    this.#destroyLongTouchDetectionListeners();

    if (this.#customTitleComponent) {
      if (this.appCustomTitleTrackShowing) {
        this.#customTitleService.removeCustomTitleFromShown(this.#id);
      }

      const animationFactory = this.#animationBuilder.build(fadeOutAnimation);

      const fadeOutPlayer = animationFactory.create(this.#domElem.firstChild);

      fadeOutPlayer.onDone(() => {
        if (this.#customTitleComponent) {
          this.#customTitleComponent.destroy();
          // eslint-disable-next-line unicorn/no-null -- TODO: delete the comment and fix the error
          this.#customTitleComponent = null;
          this.#unsubscribe.next();
          this.#unsubscribe.complete();
          this.#renderer.removeChild(this.#document.body, this.#gate);

          callback?.();
        }

        this.#vcReference.clear();
        this.#embeddedView?.destroy();
      });

      timer(this.appCustomTitleHideDelay ?? defaultHideDelay)
        .pipe(takeUntil(this.#unsubscribe))
        .subscribe(() => {
          fadeOutPlayer.play();
        });
    }
  }

  #isTouchModeActive(): boolean {
    let useTouchMode = false;
    const isRelyingOnHoveringEvents = !this.appCustomTitleOpenOnClick;

    if (
      isRelyingOnHoveringEvents &&
      this.isTouchDevice() &&
      this.appCustomTitleUseExperimentalTouchDevicesSupport()
    ) {
      // NOTE: Forcing touch mode for touch devices (touch devices doesn't support hovering)
      useTouchMode = true;
    }

    return useTouchMode;
  }

  #displayWhenLongTouchIsDetected(touchStartEvent: TouchEvent): void {
    if (touchStartEvent.touches.length === 0) {
      return;
    }
    const touchTarget = touchStartEvent.touches[0];

    this.#longTouchDetectionTimeout = setTimeout(() => {
      // NOTE: Long touch detected
      this.#xMouseCoordinate = touchTarget.clientX;
      this.#yMouseCoordinate = touchTarget.clientY;

      if (!this.appCustomTitleBlockShowing) {
        this.#showCustomTitle();
      }
    }, this.#LONG_TOUCH_DURATION_MILLIS);
  }

  #destroyLongTouchDetectionListeners(): void {
    if (isNotNullOrUndefined(this.#longTouchDetectionTimeout)) {
      clearTimeout(this.#longTouchDetectionTimeout);
      this.#longTouchDetectionTimeout = undefined;
    }
  }
}
