import { Injectable } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { fromEvent, map } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class TouchDeviceDetectorService {
  readonly #mediaQueryList = globalThis.matchMedia('(pointer: coarse)');

  readonly isTouchDevice = toSignal(
    fromEvent(this.#mediaQueryList, 'change').pipe(
      map(() => this.#checkIfIsTouchDevice()),
    ),
    { initialValue: this.#checkIfIsTouchDevice() },
  );

  #checkIfIsTouchDevice(): boolean {
    return this.#mediaQueryList.matches;
  }
}
